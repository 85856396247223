<template>
  <v-form @submit.prevent="save" ref="form" lazy-validation v-model="valid">
    <v-container>
      <v-row>
        <v-spacer />
        <v-col cols="12" xl="6" lg="6" md="6" sm="12">
          <v-autocomplete
            label="Selecionar plano"
            item-text="description"
            item-value="id"
            dense
            outlined
            hide-details
            required
            :rules="[required]"
            :items="plans"
            @change="onChange"
            v-model="model.dentalPlanId"
          />
        </v-col>
      </v-row>

      <BaseTableList title="Listagem de beneficiários">
        <template v-slot:table>
          <v-data-table
            dense
            item-key="id"
            disable-sort
            hide-default-footer
            :headers="headers"
            :items="items"
          >
            <template v-slot:[`item.value`]="{ item }">
              {{ formatMoney(item.value) }}
            </template>

            <template v-slot:[`item.effectiveDate`]="{ item }">
              {{ formatDate(item.effectiveDate) }}
            </template>
          </v-data-table>
        </template>
      </BaseTableList>

      <v-divider class="my-5" />

      <v-row>
        <v-col class="text-right">
          <BaseButton @click="back" outlined color="primary" title="Voltar" />

          <template v-if="canEdit">
            <BaseButton
              type="submit"
              color="primary"
              title="Salvar"
              :disabled="!valid"
              v-if="enableSave"
            />
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { rulesMixin } from '@/mixins/rules';
import { mapGetters } from 'vuex';
import { showMessage } from '@/helpers/messages';
import { formatMoney, formatDate } from '@/helpers/formatting';
import { MovementService } from '@/services/api/movements';

export default {
  mixins: [rulesMixin],

  props: {
    employeeId: {
      type: String,
      require: true
    }
  },

  data: () => ({
    valid: true,
    headers: [
      { text: 'Beneficiário', value: 'name' },
      { text: 'Início de vigência', value: 'effectiveDate' },
      { text: 'Parentesco', value: 'kinship' },
      { text: 'Valor', value: 'value' }
    ],
    items: [],
    plans: [],
    model: {
      productType: null,
      dentalPlanId: null
    },
    enableSave: false
  }),

  computed: {
    ...mapGetters({
      employee: 'movements/getEmployee'
    }),

    canEdit() {
      const situation =
        this.employee.situation === 'Ativo' ||
        this.employee.situation === 'Em análise na operadora' ||
        this.employee.situation === null;

      return situation;
    }
  },

  created() {
    this.getPlans();
    this.search();
  },

  methods: {
    formatMoney,
    formatDate,

    back() {
      this.$emit('back');
    },

    async getPlans() {
      try {
        const movementService = new MovementService();
        const { status, data } =
          await movementService.getAvailableMovementPlans({
            employeeId: this.employeeId,
            productType: 'Assistência odontológica'
          });

        if (status === 200) {
          this.plans = data;
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async search() {
      try {
        const movementService = new MovementService();
        const { status, data } = await movementService.getEmployeePlan({
          employeeId: this.employeeId,
          productType: 'Assistência odontológica'
        });

        if (status === 200) {
          this.model = data;

          this.enableSave = data.dentalPlanId ? false : true;

          this.onChange();
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async onChange() {
      try {
        if (this.model.dentalPlanId) {
          const movementService = new MovementService();
          const { status, data } =
            await movementService.getEmployeeAndDependentPlanValue({
              employeeId: this.employeeId,
              planId: this.model.dentalPlanId,
              productType: 'Assistência odontológica'
            });

          if (status === 200) {
            this.items = data;
          }
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async save() {
      if (!this.$refs.form.validate(true)) {
        return;
      }

      try {
        const params = Object.assign({}, this.model);
        params.employeeId = this.employeeId;
        params.productType = 'Assistência odontológica';

        const movementService = new MovementService();
        const { status } = await movementService.saveEmployeePlan(params);

        if (status === 204) {
          showMessage('success', 'Operação realizada com sucesso');
          this.search();
        }
      } catch (error) {
        throw new Error(error);
      }
    }
  }
};
</script>
